import React, { useState } from "react";
import { Button, Heading } from "@reliance/design-system";

const DeliveryDetail = ({ deliveryKey, value }) => (
  <div style={{ display: "flex", justifyContent: "space-between" }}>
    <div style={{ fontWeight: "bold", paddingRight: 20 }}>{deliveryKey}</div>
    <div style={{ textAlign: "right" }}>{value}</div>
  </div>
);

const AgreementForm = ({ checked, name, agreement, onChange }) => (
  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
    <div>
      {
        <input
          type="checkbox"
          checked={checked}
          name={name}
          onChange={onChange}
          style={{
            width: 20,
            height: 20,
            flexShrink: 1,
            marginRight: "10px",
            border: "2px solid #094063",
            borderRadius: "50%",
            color: "#094063",
          }}
        />
      }
    </div>
    <div>{agreement}</div>
  </div>
);

const FulfilmentDelivery = ({ handleGoBack, onProceed, contactDetails }) => {
  const [checkedItems, setCheckedItems] = useState({
    check1: false,
    check2: false,
    check3: false,
  });

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const allChecked = Object.values(checkedItems).every(Boolean);

  return (
    <div style={{ fontSize: 16 }}>
      <Heading level="h1" style={{ fontSize: 18 }}>
        Delivery confirmation
      </Heading>

      <div style={{ padding: "20px 0px" }}>
        <DeliveryDetail
          deliveryKey="L.G.A"
          value={contactDetails.lga || "L.G.A not provided"}
        />
        <DeliveryDetail
          deliveryKey="Address"
          value={
            contactDetails.address ||
            `Address not provided - ${contactDetails.state}`
          }
        />
        <DeliveryDetail
          deliveryKey="Landmark"
          value={contactDetails.landmark}
        />
        <DeliveryDetail
          deliveryKey="Phone Number"
          value={`${
            contactDetails.phoneNumber +
            ", " +
            contactDetails.alternativePhoneNumber
          } `}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          backgroundColor: "#F7F7F8",
          marginBottom: 10,
          padding: 5,
        }}
      >
        <AgreementForm
          checked={checkedItems.check1}
          name="check1"
          agreement="I confirm that the information provided above is correct."
          onChange={handleCheckboxChange}
        />
        <AgreementForm
          checked={checkedItems.check2}
          name="check2"
          agreement="I understand that medication requested after 12 PM may not be delivered on the same day."
          onChange={handleCheckboxChange}
        />
        <AgreementForm
          checked={checkedItems.check3}
          name="check3"
          agreement="I understand that attempting to change the delivery address after this request is submitted will cancel the prescription."
          onChange={handleCheckboxChange}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <Button
          style={{ width: "48%", fontSize: 14, height: 35 }}
          onClick={handleGoBack}
          variant="outline"
        >
          Go back
        </Button>
        <Button
          style={{ width: "48%", fontSize: 14, height: 35 }}
          onClick={onProceed}
          disabled={!allChecked}
        >
          Proceed
        </Button>
      </div>
    </div>
  );
};

export default FulfilmentDelivery;
