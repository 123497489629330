import { Button } from "@reliance/design-system";
import React from "react";
import Pill from "../img/Pill.png";
import { Heading } from "@reliance/design-system";

const FulfilmentConfirmation = ({
  handleBackToConsultation,
  DELIVERYTYPE,
  deliveryType,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 15,
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 0px 20px 0px",
      }}
    >
      <img src={Pill} alt="" width={50} />
      {deliveryType === DELIVERYTYPE.pickup && (
        <>
          <Heading
            level={"h1"}
            style={{ textAlign: "center", marginBottom: 10, fontSize: 18 }}
          >
            Prescription Pick Up request submitted
          </Heading>
          <p style={{ fontSize: 13 }}>
            You will receive a prescription code in the app within 3 business
            hours, confirming that your medication is ready for pickup.{" "}
          </p>
          <Heading level={"h1"} style={{ fontSize: 16 }}>
            Please wait for this code before heading to the pharmacy.{" "}
          </Heading>
          <p style={{ fontSize: 13 }}>
            Show the code at the pharmacy to get your medications.
          </p>
        </>
      )}

      {deliveryType === DELIVERYTYPE.delivery && (
        <>
          <Heading
            level={"h1"}
            style={{ textAlign: "center", marginBottom: 10, fontSize: 18 }}
          >
            Prescription delivery request submitted
          </Heading>
          <p style={{ fontSize: 13 }}>
            You will receive a prescription code that confirms your medication
            is ready for delivery and will be dispatched to you within 24 hours.
          </p>

          <p style={{ fontSize: 13 }}>
            Display the code to the dispatch rider to get your medications.
          </p>
        </>
      )}

      <Button
        onClick={handleBackToConsultation}
        style={{ fontSize: 14, height: 35, width: "80%", marginTop: 10 }}
      >
        Back to consultation
      </Button>
    </div>
  );
};

export default FulfilmentConfirmation;
