import React, { useState } from "react";
import PharmacyItem from "./PharmacyItem";
import PickupDisclaimer from "./PickupDisclaimer";
import { Heading, Input } from "@reliance/design-system";

export default ({ pharmacies, handleSelectedPharmacy }) => {
  const [searchText, setSearchText] = useState("");
  const filteredPharmacies = () => {
    if (pharmacies.length && searchText) {
      return pharmacies.filter(({ name, address }) => {
        if (name && address) {
          return (
            name.toLowerCase().includes(searchText.toLowerCase()) ||
            address.toLowerCase().includes(searchText.toLowerCase())
          );
        }
      });
    }
    return pharmacies;
  };

  return (
    <div style={{ display: "flex", gap: 10, flexDirection: "column" }}>
      <Heading
        level={"h1"}
        style={{ fontSize: 20, padding: "10px 0px 10px 0px" }}
      >
        Prescription Pick Up
      </Heading>
      <Input
        variant={"search"}
        onChange={(val) => setSearchText(val.target.value)}
        placeholder="Search"
        style={{
          height: 20,
          fontSize: 16,
          padding: "20px 12px 20px 12px",
        }}
      />
      <PickupDisclaimer style={{ marginTop: 60 }} />
      <div>
        {filteredPharmacies().map((val, index) => (
          <PharmacyItem
            key={index}
            pharmacy={val}
            onClick={() =>
              handleSelectedPharmacy({ ...val, isRecommended: false })
            }
          />
        ))}
      </div>
    </div>
  );
};
