import React from "react";
import PharmacyItem from "./PharmacyItem";
import { Button, Heading } from "@reliance/design-system";
import House from "../img/House.png";

const ConfirmPharmacySelectionV2 = ({
  pharmacy,
  handleGoback,
  handleProceed,
}) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 15,
        }}
      >
        <img src={House} alt=" " width={40} />
      </div>
      <div>
        <Heading
          level={"h1"}
          style={{ textAlign: "center", marginBottom: 10, fontSize: 18 }}
        >
          Confirm selection
        </Heading>
      </div>
      <PharmacyItem pharmacy={pharmacy} />
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <Heading level={"h2"}>
          Are you sure you want to proceed with this Pharmacy?
        </Heading>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 10,
          }}
        >
          <Button
            variant={"outline"}
            onClick={handleGoback}
            style={{ width: "48%", fontSize: 14, height: 35 }}
          >
            Go back
          </Button>
          <Button
            onClick={handleProceed}
            style={{ width: "48%", fontSize: 14, height: 35 }}
          >
            Proceed
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPharmacySelectionV2;
